import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Chart, ReactGoogleChartEvent } from 'react-google-charts';
import { useAnalytics } from '../Analytics';
import { useState, useEffect } from 'react';
import { countryDictionary } from '../../../data/data';
import { generateCountryMapData, getKeyByValue, getRangeMultiplier, getVibeValueByMultiplier, lightenColor, updateMapDataByDateMultiplier } from '../../../utils';
import { mockMapData } from '../../../data/mockMapData';
import { mapDropdownOptions } from '../../../data/mapDataSessions';
import { vibeColors, vibeColorsArr } from '../../../data/vibesColors';
import { googleMapsApiKey } from '../../../constants';

const mockMapDataArr = mockMapData.slice(1);

const AnalyticsMap = () => {
  const { mapTabData, setMapTabData, dateValue, selectedDays, currentVibeFilter } = useAnalytics();
  const [regionState, setRegionState] = useState<string>();
  const [displayViewMode, setDisplayViewMode] = useState<string>('region');
  const [displayViewRegion, setDisplayViewRegion] = useState<string>('001');
  const [regionStateCode, setRegionStateCode] = useState<string>();
  const [mapColors, setMapColors] = useState<string[]>([]);
  const [rangeMultiplierState, setRangeMultiplierState] = useState<number>(1);
  const [openNoDataDlg, setOpenNoDataDlg] = useState(false);

  const handleClose = () => {
    setOpenNoDataDlg(false);
  };

  useEffect(() => {
    if (currentVibeFilter) {
      const currentVibeColor = vibeColors.find((item: any) => currentVibeFilter === item.vibe)?.color;
      setMapColors(currentVibeColor ? ['#dee3e7', lightenColor(currentVibeColor, 70), currentVibeColor] : []);
    } else {
      setMapColors([])
    }
  }, [currentVibeFilter]);

  useEffect(() => {
    if (dateValue) {
      setRangeMultiplierState(getRangeMultiplier(dateValue, selectedDays))
    }
  }, [dateValue, selectedDays]);

  useEffect(() => {
    if (mapTabData?.selectedMapView) {
      setRegionStateCode(mapTabData?.selectedMapView);
      setRegionState(getKeyByValue(countryDictionary, mapTabData?.selectedMapView));
      setDisplayViewRegion(mapTabData?.selectedMapView);
    }
  }, [mapTabData]);

  useEffect(() => {
    if (regionState && regionStateCode && displayViewMode !== 'markers') {
      try {
        const res = generateCountryMapData(mockMapData, regionState, currentVibeFilter, rangeMultiplierState);

        console.log(res, mockMapData, regionState, currentVibeFilter, rangeMultiplierState);
        if (res) {
          const mapData = {
            mapData: res,
            selectedMapView: regionStateCode,
          };
          setMapTabData(mapData);
          setDisplayViewRegion(regionStateCode);
        }
      } catch (e) {
        console.error(e);
        setRegionStateCode('001');
        setDisplayViewRegion('001');
        setOpenNoDataDlg(true);
      }
    }
    if (regionStateCode) {
      const viewMode = getKeyByValue(mapDropdownOptions, regionStateCode) ? 'region' : 'markers';
      setDisplayViewMode(viewMode);
    }
  }, [regionState, regionStateCode]);

  const handleBackToAllView = () => {
    const newMapDataFiltered = updateMapDataByDateMultiplier(mockMapData, currentVibeFilter, rangeMultiplierState);
    setMapTabData({
      mapData: newMapDataFiltered,
      selectedMapView: mapDropdownOptions['World'],
    });
    setRegionStateCode('001');
    setDisplayViewRegion('001');
    setRegionState('');
  }

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: "select",
      callback: ({ chartWrapper }) => {
        setRegionState('');
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 0) return;
        const region = String(mockMapDataArr[selection[0].row][0]);
        setRegionStateCode(countryDictionary[region] ? countryDictionary[region] : '001');
        setRegionState(region);
      },
    },
  ];

  const renderCountryData = () => {
    const regionValue = getKeyByValue(countryDictionary, displayViewRegion);
    const newArr = mockMapData.map(innerArray => [...innerArray]);
    const newMapData: any = newArr.slice(1).find((item) => {
      return item[0] === regionValue
    });

    const vibeIndex = getVibeValueByMultiplier(newMapData[1], rangeMultiplierState);
    const formatVibeIndex = vibeIndex === 11 ? 12 : vibeIndex;
    const vibeValue = vibeColors.find((item: any, index) => index === formatVibeIndex)?.vibe;
    const toolTipArr = newMapData[3].split('\n');
    const totalSessions = toolTipArr[0].replace('Total Sessions: ', '') * rangeMultiplierState;

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography variant='body1'>Country: {newMapData[0]}</Typography>
        <Typography variant='body1'>Dominant Vibe: {vibeValue}</Typography>
        <Typography variant='body1'>Total Sessions: {totalSessions}</Typography>
      </Box>
    )
  }

  return (<Box sx={{
    width: '100%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
  }}>
    <Dialog
      sx={{
        '.MuiDialog-paper': {
          padding: '20px',
          margin: 0,
          boxSizing: 'border-box',
          border: '1px solid #E2E2E2',
          boxShadow: 'none',
        }
      }}
      open={openNoDataDlg}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {regionState}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          No data for this country
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose} 
          autoFocus
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
    {regionStateCode !== '001'
      ?
      <Box sx={{
        mr: 'auto',
        mb: '15px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
      }}>
        <Button
          sx={{
            mr: '20px',
            color: '#000',
            borderColor: '#C9C9C9',
            '&:hover': {
              borderColor: '#C9C9C9',
              boxShadow: 'none',
              opacity: '0.7'
            },
            '&:active': {
              boxShadow: 'none',
              borderColor: '#C9C9C9',
            },
          }}
          onClick={handleBackToAllView}
          variant="outlined"
        >
          Back to All World View
        </Button>
        {getKeyByValue(countryDictionary, displayViewRegion) && renderCountryData()}
      </Box>
      : ''}
    <Chart
      chartEvents={chartEvents}
      mapsApiKey={googleMapsApiKey}
      chartType="GeoChart"
      width="100%"
      height="700px"
      data={mapTabData.mapData}
      options={{
        sizeAxis: { minValue: 0, maxValue: 20 * rangeMultiplierState, minSize: 8 },
        ...(displayViewRegion === '001' ? {} : { region: displayViewRegion }),
        colorAxis: {
          minValue: 0, 
          maxValue: 17, 
          colors: currentVibeFilter && mapColors && mapColors.length > 1 ? mapColors : vibeColorsArr
        },
        backgroundColor: '#fbfbfb',
        'backgroundColor.strokeWidth': 0,
        datalessRegionColor: getKeyByValue(mapDropdownOptions, regionStateCode) ? '#bcbcbc' : '#ebebeb',
        defaultColor: '#fbfbfb',
        displayMode: displayViewMode,
        legend: 'none',
        tooltip: { isHtml: true },
      }}
    />
  </Box>);
}

export default AnalyticsMap;
