import { countryDictionary } from '../data/data';
import { mapCities } from '../data/mapDataCities';
import { mapDataSessions } from '../data/mapDataSessions';
import { mockMapData } from '../data/mockMapData';
import { vibeColors, vibeColorsArr } from '../data/vibesColors';

export const emailPattern = {
  value: /^\S+@\S+\.\S+$/,
  message: "Entered value does not match email format",
};

export const passwordPattern = {
  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
  message:
    "Password must contain at least 8 characters, one uppercase, one lowercase and one number",
};

export const mobilePhonePattern = {
  value:
    /^\+?(\(\d{1,3}\)|\d{1,3})?\s?(\d{1,3})?[ -]?(\d{2,4}[ -]?\d{2,4}[ -]?\d{2,4})$/,
  message: "Entered value does not match mobile phone format",
};

export const websiteUrlPattern = {
  value: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
  message: "Entered value does not match website url format",
};


interface MyObject {
  [key: string]: string;
}

export function filterEmptyValues(obj: MyObject): MyObject {
  const result: MyObject = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== '') {
      result[key] = obj[key];
    }
  }

  return result;
}

export function generateVokaturi() {
  const graceful = Math.floor(Math.random() * 101);
  const harmony = Math.floor(Math.random() * (101 - graceful));
  const peaceful = Math.floor(Math.random() * (101 - graceful - harmony));
  const positive = Math.floor(Math.random() * (101 - graceful - harmony - peaceful));
  const other = 100 - (graceful + harmony + peaceful + positive);
  const vokaturi = {
    graceful,
    harmony,
    peaceful,
    positive,
    other
  };

  return vokaturi
}

interface ITransform {
  graceful: number;
  harmony: number;
  peaceful: number;
  positive: number;
  other: number;
}

export function transformIntentionToArray(obj: ITransform): string[] {
  const result: string[] = [];
  let totalSize = 0;

  const order = ["peaceful", "harmony", "graceful", "positive", "other"];

  order.forEach(key => {
    const count = Math.min(obj[key as keyof ITransform], 1, 7 - result.length);
    const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);

    for (let i = 0; i < count; i++) {
      result.push(capitalizedKey);
      totalSize++;
    }
  });

  return result;
}

export function generateIntention() {
  const selectedIntentionsOptions = ['Graceful', 'Harmony', 'Peaceful', 'Positive', 'Other'];
  const numberOfIntentions = Math.floor(Math.random() * 3) + 1;
  const selectedIntentions = [];

  for (let i = 0; i < numberOfIntentions; i++) {
    const randomIndex = Math.floor(Math.random() * selectedIntentionsOptions.length);
    selectedIntentions.push(selectedIntentionsOptions[randomIndex]);
    selectedIntentionsOptions.splice(randomIndex, 1);
  }

  return selectedIntentions;
}

export function generateLegenIntention(count: number) {
  const arr = [];

  for (let i = 0; i < count; i++) {
    const intention = generateIntention();

    arr.push(
      intention
    )
  }

  return arr
}

export function generateMockSessions(count: number) {
  const mockSessions = [];

  for (let i = 0; i < count; i++) {
    const id = i + 1;
    const day = Math.floor(Math.random() * 31) + 1;
    const month = Math.floor(Math.random() * 12) + 1;
    const date = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}`;
    const time = `${Math.floor(Math.random() * 24).toString().padStart(2, '0')}:${Math.floor(Math.random() * 60).toString().padStart(2, '0')}:${Math.floor(Math.random() * 60).toString().padStart(2, '0')}`;
    const lengthOptions = ['Short', 'Medium', 'Long'];
    const length = lengthOptions[Math.floor(Math.random() * lengthOptions.length)];
    const countryOptions = ['Germany', 'France', 'Italy', 'Spain', 'United States'];
    const country = countryOptions[Math.floor(Math.random() * countryOptions.length)];
    const selected_intentions = generateIntention();
    const vokaturi = generateVokaturi();
    const vibes = generateVokaturi();
    const stress = Math.floor(Math.random() * 101);
    const light = Math.floor(Math.random() * 10) + 1;
    const resilience = Math.floor(Math.random() * 11);
    const hrv = Math.floor(Math.random() * 101);

    mockSessions.push({
      id,
      date,
      time,
      length,
      country,
      vibes,
      selected_intentions,
      vokaturi,
      stress,
      light,
      resilience,
      hrv
    });
  }

  return mockSessions;
}

export function getDate(dateString: string) {
  var parts = dateString.split(".");
  var year = parseInt(parts[2], 10);
  var month = parseInt(parts[1], 10) - 1;
  var day = parseInt(parts[0], 10);

  var date = new Date(year, month, day);
  return date
}


export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === 'date') {

    let date1 = getDate(`${a[orderBy]}.2024`).getTime();
    let date2 = getDate(`${b[orderBy]}.2024`).getTime();

    if (date2 < date1) {
      return -1;
    }
    if (date2 > date1) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }

  return 0;
}

type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  const newArr = stabilizedThis.map((el) => el[0]);

  return newArr;
}

export function updateMapDataByDateMultiplier(
  mapData: any[],
  currentVibeFilter: string,
  rangeMultiplier: number,
): Array<string[]> {
  const newArr = mapData.map(innerArray => [...innerArray]);

  const newMapData = newArr.slice(1).map((item: any, index) => {
    const sessionsNumber = item[3].split('\n')[0].replace('Total Sessions: ', '');
    const vibeIndex = getVibeValueByMultiplier(item[1], rangeMultiplier);
    const formatVibeIndex = vibeIndex === 11 ? 12 : vibeIndex;
    const vibeValue = vibeColors.find((item: any, index) => index === formatVibeIndex)?.vibe;
    item[1] = formatVibeIndex;
    item[3] = [`Total Sessions: ${sessionsNumber * rangeMultiplier}`, `Vibe: ${vibeValue}`].join('\n');

    return item
  });

  const filteredByVibeArr = currentVibeFilter
    ? newMapData.map((item: any) => {
      const toolTipCol = item[3].split('\n');
      const vibeValue = item[3].split('\n')[1].replace('Vibe: ', '');
      if (vibeValue.trim() === currentVibeFilter) {
        item[1] = 18;
      } else {
        item[1] = item[1] > 0 ? item[1] - 1 : item[1];
      }

      let newToolTipCol = toolTipCol;
      newToolTipCol[1] = `${currentVibeFilter}: ${((item[1] / 18) * 100).toFixed(0)}%`;

      item[3] = newToolTipCol.join('\n');
      return item
    })
    : newMapData;

  return [
    newArr[0],
    ...filteredByVibeArr
  ];
}

export function generateCountryMapData(
  mapData: any[],
  country: string,
  currentVibeFilter: string,
  rangeMultiplierState: number,
  mapTitles: any[] = ["City", "Value", "Area", { type: 'string', role: 'tooltip' }]
): Array<string[]> {
  const countryObj = mapCities.find(
    item => {
      return country === item.country //countryDictionary[item.country]
    }
  );

  if (!countryObj) {
    // throw new Error('No country data');
    console.error('No country data');
    return [];
  };

  const newArr = mapData.map(innerArray => [...innerArray]);
  const newMapData: any = newArr.slice(1).find((item) => {
    return item[0] === country
  });
  const totalSessions = newMapData[3].split('\n')[0].replace('Total Sessions: ', '') * rangeMultiplierState;

  const colsArr: any = countryObj?.cities.slice(0, 199);
  let remainingSessions = totalSessions;

  const cols = colsArr.map((item: any, index: number, arr: any) => {
    const minSessions = 3 * (rangeMultiplierState / 3);
    const maxSessions = 3 * (rangeMultiplierState / 5);
    const randomIndex = Math.floor(Math.random() * vibeColorsArr.length + 1);
    const formatIndex = randomIndex > 0 ? randomIndex - 1 : randomIndex;
    const sessionsForCity = remainingSessions > 0 && formatIndex !== 11 ? Math.floor(Math.random() * (maxSessions) + minSessions) : 0;
    remainingSessions = remainingSessions > 0 ? remainingSessions - sessionsForCity : 0;
    const vibeLabel = sessionsForCity === 0 || formatIndex === 11 ? 'None' : vibeColors[formatIndex].vibe;


    if (currentVibeFilter) {
      return [
        item,
        sessionsForCity === 0 ? 0 : formatIndex,
        sessionsForCity,
        `Total Sessions: ${sessionsForCity}
        ${currentVibeFilter}: ${sessionsForCity === 0 || currentVibeFilter === 'Off' ? '0' : ((formatIndex / 18) * 100).toFixed(0)}%`
      ]
    } else {
      return [
        item,
        sessionsForCity === 0 ? 11 : formatIndex,
        sessionsForCity,
        `Total Sessions: ${sessionsForCity}
        ${`Vibe: ${vibeLabel}`}`
      ]
    }
  });

  if (cols) {
    return [
      mapTitles,
      ...cols
    ];
  } else {
    return [mapTitles];
  }
}

export function sessionsToMapFilters(mapDataSessions: Array<{ [key: string]: any }>): { [key: string]: any[] } {
  return mapDataSessions.reduce((acc: { [key: string]: any }, continentItm) => {
    if (!acc[continentItm.continent]) {
      acc[continentItm.continent] = mapDataSessions.filter(
        (countryItem) => countryItem.continent === continentItm.continent
      ).reduce((acc: any, countryItem) => {
        if (!acc[countryItem.country]) {
          const cities = Array.from(
            new Set(
              mapDataSessions.filter(
                (cityItem) => cityItem.country === countryItem.country
              ).map((cityItem) => cityItem.city)
            )
          );

          acc[countryItem.country] = {
            countryName: countryItem.countryName,
            country: countryItem.country,
            continent: countryItem.continent,
            cities,
          };
        }
        return acc;
      }, {});
    }

    return acc;
  }, {});
}

export function generateDateRangeString(start: Date, end: Date) {
  const dateRange = [];

  for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
    dateRange.push(new Date(d).toISOString().split('T')[0]);
  }

  return dateRange;
}

export function addDaysToDate(date: Date, days: number) {
  date.setDate(date.getDate() + days);
  return date;
}

export function searchSort(array: any, searchValue: string) {
  return array.filter((row: any) => {
    for (const key in row) {
      if (row.hasOwnProperty(key)) {
        const value = row[key];
        if (typeof value === 'string' && value.toLowerCase().includes(searchValue.toLowerCase())) {
          return true;
        }
      }
    }
    return false;
  });
}

export const isObjectEmpty = (objectName: any) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};

export function hasNonEmptyArrays(obj: any) {
  for (let key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      return true;
    }
  }
  return false;
}

export const getVibeValueByMultiplier = (value: number, multiplier: number) => {
  let newValue = value;

  switch (multiplier) {
    case 1:
      newValue = value;
      break;
    case 4:
      newValue = value + 2;
      break;
    case 6:
      newValue = value - 2;
      break;
    case 15:
      newValue = value - 4;
      break;
    case 25:
      newValue = value + 3;
      break;
    case 36:
      newValue = value - 1;
      break;
    default:
      newValue = value - (multiplier % 10);
      break;
  }

  return newValue > 18 ? 18 : parseInt(Math.abs(newValue).toFixed(0))
}


export const getRangeMultiplier = (dateValue: string, selectedDays: any = 1) => {
  let rangeMultiplier;

  if (dateValue === 'date range') {
    rangeMultiplier = selectedDays;
  } else if (dateValue !== 'date range') {

    switch (dateValue) {
      case 'today':
        rangeMultiplier = 1;
        break;
      case 'week':
        rangeMultiplier = 4;
        break;
      case '2 weeks':
        rangeMultiplier = 6;
        break;
      case 'month':
        rangeMultiplier = 15;
        break;
      case '3 month':
        rangeMultiplier = 25;
        break;
      case 'year':
        rangeMultiplier = 36;
        break;
      default:
        rangeMultiplier = 1;
        break;
    }
  }
  return rangeMultiplier
}

export function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find(key => object[key] === value) || '';
}

export function lightenColor(hex: string, percent: number) {
  hex = hex.replace('#', '');
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  r += (255 - r) * percent / 100;
  g += (255 - g) * percent / 100;
  b += (255 - b) * percent / 100;
  r = Math.min(r, 255);
  g = Math.min(g, 255);
  b = Math.min(b, 255);
  return "#" + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
}

export function addHours(date: Date, hours: number) {
  const hoursToAdd = hours * 60 * 60 * 1000;
  date.setTime(date.getTime() + hoursToAdd);
  return date;
}

export function countPercents(a: number, b: number) {
  const defaultResult = {a: 0, b: 0};

  if (typeof a !== 'number' || typeof b !== 'number') {
    return defaultResult;
  }
  const total = a + b;
  
  if (total === 0) {
    return defaultResult;
  }

  const aPerc = Number(((a / total) * 100).toFixed());
  const bPerc = Number(((b / total) * 100).toFixed());

  return {
    a: aPerc,
    b: aPerc + bPerc > 100 ? bPerc - 1 : bPerc,
  };
}
