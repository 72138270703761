import {
  Box,
  Stack,
} from '@mui/material';
import { useState, useEffect } from 'react';

import { mapDataSessions, mapDropdownOptions } from '../../data/mapDataSessions';
import { mockMapData } from '../../data/mockMapData';
import GeolocationFilterDrawer from './GeolocationFilterDrawer';
import { addDaysToDate, generateDateRangeString, updateMapDataByDateMultiplier, sessionsToMapFilters, generateCountryMapData, getKeyByValue, isObjectEmpty } from '../../utils';
import { countryDictionary, geoFilters } from '../../data/data';

const defaultDateRange = generateDateRangeString(addDaysToDate(new Date(), -7), new Date());
const locationFilters = geoFilters;


const AnalyticsMapFilters = (
  {
    dateValue,
    startDate,
    endDate,
    mapTabData,
    setMapTabData,
    setMapSidebarFiltersOpened,
    selectedDays,
    currentVibeFilter,
    setSelectedCountryFilters,
    isLiveData
  }: any) => {
  const [countryFilters, setCountryFilters] = useState<any>({});
  const [rangeMultiplierState, setRangeMultiplierState] = useState<number>(1);

  useEffect(() => {
    applyDateFilter(currentVibeFilter);
  }, [dateValue, currentVibeFilter, selectedDays]);

  const applyDateFilter = (currentVibeFilter: string) => {
    let rangeMultiplier;
    if (dateValue === 'date range') {
      rangeMultiplier = selectedDays;
    } else if (dateValue !== 'date range') {

      switch (dateValue) {
        case 'today':
          rangeMultiplier = 1;
          break;
        case 'week':
          rangeMultiplier = 4;
          break;
        case '2 weeks':
          rangeMultiplier = 6;
          break;
        case 'month':
          rangeMultiplier = 15;
          break;
        case '3 month':
          rangeMultiplier = 25;
          break;
        case 'year':
          rangeMultiplier = 36;
          break;
        default:
          rangeMultiplier = 1;
          break;
      }
    }
    setRangeMultiplierState(rangeMultiplier);
    applyFilters(countryFilters, currentVibeFilter, rangeMultiplier);
  }

  const applyFilters = (filters: any, currentVibeFilter: string, rangeMultiplier: number) => {
    setCountryFilters({ ...filters });
    setSelectedCountryFilters({ ...filters });
    const continent = Object.keys(filters)[0];
    const selectedMapView = mapDropdownOptions[continent] || mapTabData.selectedMapView;
    const isContinentCode = getKeyByValue(mapDropdownOptions, mapTabData.selectedMapView);

    if (continent || isContinentCode) {
      if (!isObjectEmpty(filters) && filters[continent] !== '' && !isLiveData) {
        const newMapDataFiltered = generateCountryMapData(mockMapData, getKeyByValue(countryDictionary, filters[continent]), currentVibeFilter, rangeMultiplier);
        setMapTabData({
          mapData: newMapDataFiltered,
          selectedMapView: filters[continent],
        });
        return;
      } else if (!isLiveData) {
        const newMapDataFiltered = updateMapDataByDateMultiplier(mockMapData, currentVibeFilter, rangeMultiplier);

        setMapTabData({
          mapData: newMapDataFiltered,
          selectedMapView: selectedMapView ? selectedMapView : mapDropdownOptions['World'],
        });
        return;
      }
    }

    const newMapDataFiltered = selectedMapView && selectedMapView !== '001'
      ? generateCountryMapData(mockMapData, getKeyByValue(countryDictionary, selectedMapView), currentVibeFilter, rangeMultiplier)
      : updateMapDataByDateMultiplier(mockMapData, currentVibeFilter, rangeMultiplier);

    if (!isLiveData) {
      setMapTabData({
        mapData: newMapDataFiltered,
        selectedMapView: selectedMapView ? selectedMapView : mapDropdownOptions['World'],
      });
    }
  }

  return <GeolocationFilterDrawer
    filters={locationFilters}
    applyFilters={(filters: any) => applyFilters(filters, currentVibeFilter, rangeMultiplierState)}
    setMapSidebarFiltersOpened={setMapSidebarFiltersOpened}
    mainTitle="Locations"
  />;
}

export default AnalyticsMapFilters;
