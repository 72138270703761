import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Chart, ReactGoogleChartEvent } from 'react-google-charts';
import { useAnalytics } from '../AnalyticsReal';
import { useState, useEffect } from 'react';
import { continentsMap, countryDictionary, countryCodeNameDictionary } from '../../../data/data';
import { getKeyByValue, getRangeMultiplier } from '../../../utils';
import { mockMapData } from '../../../data/mockMapData';
import { mapDropdownOptions } from '../../../data/mapDataSessions';
import { vibeColorsArr } from '../../../data/vibesColors';
import { googleMapsApiKey } from '../../../constants';
import { getSessionMapAnalytics } from '../../../service/sessionMapAnalyticsService';

const AnalyticsMapReal = () => {
  const {
    mapTabData,
    setMapTabData,
    dateValue,
    selectedDate,
    selectedDays,
    currentVibeFilter,
    selectedCountryFilters
  } = useAnalytics();
  const [regionState, setRegionState] = useState<string>();
  const [displayViewMode, setDisplayViewMode] = useState<string>('region');
  const [displayViewRegion, setDisplayViewRegion] = useState<string>('001');
  const [regionStateCode, setRegionStateCode] = useState<string>();
  const [rangeMultiplierState, setRangeMultiplierState] = useState<number>(1);
  const [openNoDataDlg, setOpenNoDataDlg] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleClose = () => {
    setOpenNoDataDlg(false);
  };

  useEffect(() => {
    const continent = Object.keys(selectedCountryFilters)[0];
    const mapDropdownOption = mapDropdownOptions[continent] || '001';
    const filterCountry = Object.values(selectedCountryFilters)[0];
    const country = (!!filterCountry || !!continent) ? filterCountry : countryDictionary[regionState as string];
    if (!!country) {
      setRegionStateCode(country as string);
      setDisplayViewRegion(country as string);
      setDisplayViewMode('markers');
    } else if (!!continent) {
      setRegionStateCode(mapDropdownOption);
      setDisplayViewRegion(mapDropdownOption);
      setRegionState('');
      setDisplayViewMode('region');
    } else {
      setRegionStateCode('001');
      setDisplayViewRegion('001');
      setRegionState('');
      setDisplayViewMode('region');
    }

  }, [selectedCountryFilters]);

  useEffect(() => {
    const from = selectedDate.startDay || new Date();
    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0);
    from.setMilliseconds(0);

    const to = selectedDate.endDay || new Date(from.getTime());
    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(59);
    to.setMilliseconds(999);

    const continent = Object.keys(selectedCountryFilters)[0];
    const continentCode = continent ? continentsMap[continent as string] : '';
    const country = Object.values(selectedCountryFilters)[0] || regionState;
    const hasCountry = !!country;
    setIsLoading(true);
    
    getSessionMapAnalytics(
      {from, to},
      country as string
    ).then((data) => {
      let filteredData = data;

      if (!!currentVibeFilter) {
        filteredData = filteredData.filter((item: any) => item.topVibe?.vibe === currentVibeFilter);
      }

      if (!!continentCode) {
        filteredData = filteredData.filter((item: any) => item.continent === continentCode);
      }

      setMapTabData({
        ...mapTabData,
        mapData: [
          [
            hasCountry ? "City" : "Country",
            "Value",
            "Total Sessions",
            { type: 'string', role: 'tooltip' }
          ],
          ...filteredData.map((item: any) => [
            hasCountry ? item.city : (countryCodeNameDictionary[item.country] || item.country),
            vibeColorsArr.indexOf(item?.topVibe?.color?.toLowerCase() || 0),
            item.totalSessions,
            [
              `Total Sessions: ${item.totalSessions}`,
              `Vibe: ${item.topVibe.vibe}`,
            ].join('\n'),
          ]),
        ],
      });
      // if (data.length === 0) {
      //   setOpenNoDataDlg(true);
      // }
      setIsLoading(false);
    });
  }, [selectedDate, currentVibeFilter, regionStateCode]);

  useEffect(() => {
    if (dateValue) {
      setRangeMultiplierState(getRangeMultiplier(dateValue, selectedDays))
    }
  }, [dateValue, selectedDays]);

  const handleBackToAllView = () => {
    setRegionStateCode('001');
    setDisplayViewRegion('001');
    setRegionState('');
    setDisplayViewMode('region');
  }

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: "select",
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 0) {
          setRegionState('');
          return;
        }
        const region = String(mapTabData.mapData[selection[0].row+1][0]);
        const regionCode = region ? region : '001';
        setRegionStateCode(regionCode);
        setRegionState(region);
        setDisplayViewRegion(countryDictionary[region]);

        const viewMode = getKeyByValue(mapDropdownOptions, regionCode) ? 'region' : 'markers';
        setDisplayViewMode(viewMode);
      },
    },
  ];

  const renderCountryData = () => {
    const regionValue = getKeyByValue(countryDictionary, displayViewRegion);
    const newArr = mockMapData.map(innerArray => [...innerArray]);
    const newMapData: any = newArr.slice(1).find((item) => {
      return item[0] === regionValue
    });

    const vibeValues = mapTabData.mapData.reduce((acc: any, item: any, index: any) => {
      if (index === 0) return acc;

      const vibe = (item[item.length - 1].split('\n')[1] || '').replace('Vibe: ', '');

      if (vibe === '') return acc;

      acc[vibe] = {
        vibe,
        total: acc[vibe]?.total ? acc[vibe]?.total + 1 : 1
      };

      return acc;
    }, {});

    const vibeValue = (Object.values(vibeValues).sort((a: any, b: any) => b.total - a.total)[0] as any)?.vibe;
    const totalSessions = mapTabData.mapData.reduce((acc: any, item: any, index: any) => {
      if (index === 0) return acc;

      return acc + item[2];
    }, 0);

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography variant='body1'>Country: {newMapData[0]}</Typography>
        <Typography variant='body1'>Dominant Vibe: {vibeValue}</Typography>
        <Typography variant='body1'>Total Sessions: {totalSessions}</Typography>
      </Box>
    )
  }

  return (<Box sx={{
    width: '100%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
  }}>
    <Dialog
      sx={{
        '.MuiDialog-paper': {
          padding: '20px',
          margin: 0,
          boxSizing: 'border-box',
          border: '1px solid #E2E2E2',
          boxShadow: 'none',
        }
      }}
      open={openNoDataDlg}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {regionState}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          No data for this country
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose} 
          autoFocus
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
    {regionStateCode !== '001'
      ?
      <Box sx={{
        mr: 'auto',
        mb: '15px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
      }}>
        <Button
          sx={{
            mr: '20px',
            color: '#000',
            borderColor: '#C9C9C9',
            '&:hover': {
              borderColor: '#C9C9C9',
              boxShadow: 'none',
              opacity: '0.7'
            },
            '&:active': {
              boxShadow: 'none',
              borderColor: '#C9C9C9',
            },
          }}
          onClick={handleBackToAllView}
          variant="outlined"
        >
          Back to All World View
        </Button>
        {getKeyByValue(countryDictionary, displayViewRegion) && renderCountryData()}
      </Box>
      : ''}
      {
      isLoading ? <Typography>Loading...</Typography> :
        <Chart
          chartEvents={chartEvents}
          mapsApiKey={googleMapsApiKey}
          chartType="GeoChart"
          width="100%"
          height="700px"
          {...(mapTabData.mapData.length < 2 ? {data: [mapTabData.mapData[0].slice(0, 1)]} : {data: [...mapTabData.mapData]})}
          options={{
            sizeAxis: { minValue: 0, maxValue: 20 * rangeMultiplierState, minSize: 8 },
            ...(displayViewRegion === '001' ? {} : { region: displayViewRegion }),
            colorAxis: {
              minValue: 0, 
              maxValue: 17, 
              colors: vibeColorsArr
            },
            backgroundColor: '#fbfbfb',
            'backgroundColor.strokeWidth': 0,
            datalessRegionColor: getKeyByValue(mapDropdownOptions, regionStateCode) ? '#bcbcbc' : '#ebebeb',
            defaultColor: '#fbfbfb',
            displayMode: displayViewMode,
            legend: 'none',
            tooltip: { isHtml: true },
          }}
        />
    }
  </Box>);
}

export default AnalyticsMapReal;
