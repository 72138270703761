import { Box, Typography, Tabs, Tab, Tooltip, IconButton } from '@mui/material';
import { useState, useEffect } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useAnalytics } from '../AnalyticsReal';
import LegendDialog from '../../blocks/LegendDialog';
import { getUsageAnalytics } from '../../../service/usageAnalyticsService';
import { TAnalyticsUsageTop5, TMeditationByLocation, TVibeResponse } from '../../../types/types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function buildTabsData(top5: TAnalyticsUsageTop5): any {
  const top5Vibes: { [key: string]: number } = {};
  const top5Songs: { [key: string]: number } = {};
  const top5Intentions: { [key: string]: number } = {};

  top5.vibes.forEach(item => {
    top5Vibes[item.vibe] = item.totalAppearances;
  });

  top5.songs.forEach(item => {
    top5Songs[item.audioTitle] = item.totalAppearances;
  });

  top5.intentions.forEach(item => {
    top5Intentions[item.vibe] = item.totalAppearances;
  });

  return [top5Vibes, top5Songs, top5Intentions];
}


interface DataItem {
  id: number;
  value: number;
  label: string;
  name: string;
  color: string;
}

interface ChartData {
  data: DataItem[];
  valueFormatter?: any;

}

function buildChartData({home, work, onTheGo}: TMeditationByLocation): ChartData[] {
  const colors: string[] = ['#F2994A', '#A460F6', '#F2C94C'];

  const generateItem = (index: number, name: string, value: number): DataItem => {
    return {
      id: index,
      value: value,
      label: `${value}% ${name}`,
      name: name,
      color: colors[index],
    };
  };

  const names: string[] = ['Home', 'Work', 'On-the-go'];
  const totalSessions = home + work + onTheGo;

  const data: DataItem[] = [
    generateItem(
      0,
      names[0],
      Number(((home / totalSessions) * 100).toFixed())
    ),
    generateItem(
      1,
      names[1],
      Number(((work / totalSessions) * 100).toFixed())
    ),
    generateItem(
      2,
      names[2],
      Number(((onTheGo / totalSessions) * 100).toFixed())
    )
  ];

  return [{ data: data, valueFormatter: (value: number | null) => `` }];
}

export function buildVokaturiScore(vibes: TVibeResponse[]) {
  const totalAppearances = vibes.reduce((acc, item) => {
    return acc + item.totalAppearances;
  }, 0);

  return vibes.map(item => ({
    vibe: item.vibe,
    color: item.color,
    value: Number(((item.totalAppearances / totalAppearances) * 100).toFixed())
  }));
}

const AnalyticsUsageReal = () => {
  const [showLegend, setShowLegend] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [avgLightScore, setAvgLightScore] = useState('');
  const [avgResilienceScore, setAvgResilienceScore] = useState('');
  const [avgCoherenceScore, setAvgCoherenceScore] = useState('');
  const [avgHrv, setAvgHrv] = useState('');
  const [vokaturiState, setVokaturiState] = useState<{vibe: string; color: string; value: number}[]>([]);
  const [pieChartState, setPieChartState] = useState<ChartData[]>([{data: []}]);
  const [tabsData, setTabsData] = useState<any>([[], [], []]);
  const { dateValue, selectedDate } = useAnalytics();

  useEffect(() => {
    const buildUsageAnalytics = async () => {
      try {
        const from = selectedDate.startDay || new Date();
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        from.setMilliseconds(0);

        const to = selectedDate.endDay || new Date(from.getTime());
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        to.setMilliseconds(999);

        const data = await getUsageAnalytics({from, to});

        const resilienceScore = data.averageResilienceScore > 0 ? (1 - data.averageResilienceScore).toFixed(2) : '-';

        setTabsData(buildTabsData(data.top5));
        setAvgHrv(`${data.averageHrv || '-'}`);
        setAvgCoherenceScore(`${data.averageCoherenceScore?.toFixed(2) || '-'}`);
        setAvgResilienceScore(resilienceScore);
        setAvgLightScore(`${data.averageLightScore || '-'}`);
        setVokaturiState([...buildVokaturiScore(data.vokaturi)]);
        setPieChartState(buildChartData(data.meditationByLocation));
      } catch(error: any) {
        console.error(error);
      }
    };

    buildUsageAnalytics();
  }, [selectedDate]);

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const chartSizing = {
    margin: { right: 5 },
    width: 180,
    height: 180,
    legend: { hidden: true },
  };

  const renderPieChartData = () => {
    return (pieChartState[0]?.data || []).map((item) => {

      const labels = item.label.split('%');
      return (
        <Box
          key={item.id}
          sx={{
            mb: '10px',
            display: 'flex',
            alignItems: 'baseline',
            fontSize: '16px',
            fontWeight: '400',
            '&::before': {
              content: '""',
              mr: '10px',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: `${item.color}`,
            },
          }}
        >
          <Typography
            sx={{
              mr: '14px',
              minWidth: '35px',
              fontSize: '16px',
              fontWeight: '500',
            }}
            variant='body1'
            component={'span'}
          >
            {labels[0]}%
          </Typography>
          {labels[1]}
        </Box>
      )
    });
  };

  const renderVokaturiBlock = () => {

    return (
      <Box sx={{
        mx: '5px',
        mb: '10px',
        border: '1px solid #E2E2E2',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        padding: '20px 30px',
        paddingBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'baseline',
        maxWidth: '50%',
        width: '100%',
        boxSizing: 'border-box'
      }}>
        <Box sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '20px',
        }}>
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: '400',
              textTransform: 'uppercase',
              letterSpacing: '.04em;',
              color: '#333333'
            }}
            variant='body1'
          >
            Vokaturi
          </Typography>
          <IconButton
            onClick={() => setShowLegend(!showLegend)}
            sx={{
              p: 0,
              transform: 'translateX(10px)'
            }}
          >
            {showLegend ?
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="8" fill="#A460F6" />
                <path d="M8 7.472H11.168V13.168H12.496V14.624H8V13.168H9.328V8.912H8V7.472ZM10.08 4C10.4107 4 10.6773 4.10133 10.88 4.304C11.0827 4.50667 11.184 4.77333 11.184 5.104C11.184 5.43467 11.0827 5.70133 10.88 5.904C10.6773 6.10667 10.4107 6.208 10.08 6.208C9.74933 6.208 9.48267 6.10667 9.28 5.904C9.088 5.70133 8.992 5.43467 8.992 5.104C8.992 4.77333 9.088 4.50667 9.28 4.304C9.48267 4.10133 9.74933 4 10.08 4Z" fill="white" />
              </svg>
              : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="8" fill="#E2E2E2" />
                <path d="M8 7.472H11.168V13.168H12.496V14.624H8V13.168H9.328V8.912H8V7.472ZM10.08 4C10.4107 4 10.6773 4.10133 10.88 4.304C11.0827 4.50667 11.184 4.77333 11.184 5.104C11.184 5.43467 11.0827 5.70133 10.88 5.904C10.6773 6.10667 10.4107 6.208 10.08 6.208C9.74933 6.208 9.48267 6.10667 9.28 5.904C9.088 5.70133 8.992 5.43467 8.992 5.104C8.992 4.77333 9.088 4.50667 9.28 4.304C9.48267 4.10133 9.74933 4 10.08 4Z" fill="white" />
              </svg>
            }
          </IconButton>
        </Box>
        <Box sx={{
          width: '100%',
          height: '32px',
          display: 'flex',
        }}>
          {
            vokaturiState.map((item) => (
              <Tooltip title={item.vibe}>
                <Box
                  sx={{
                    display: 'block',
                    height: '100%',
                    mr: item.value !== 0 ? '2px' : '0px',
                    width: `${item.value}%`,
                    borderRadius: '4px',
                    backgroundColor: item.color
                  }}
                  component="span"
                ></Box>
              </Tooltip>
            ))
          }
        </Box>
      </Box>
    )
  }

  const renderStatusBlock = (title: string, text: string, isFullSize: boolean = false) => {
    return (
      <Box sx={{
        mx: '5px',
        mb: '10px',
        border: '1px solid #E2E2E2',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        padding: '20px 30px',
        paddingBottom: isFullSize ? '50px' : '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'baseline',
        maxWidth: isFullSize ? '100%' : '50%',
        width: '100%',
        boxSizing: 'border-box'
      }}>
        <Typography
          sx={{
            mb: '15px',
            fontSize: '13px',
            fontWeight: '400',
            textTransform: 'uppercase',
            letterSpacing: '.04em;',
            color: '#333333'
          }}
          variant='body1'
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '34px',
            lineHeight: '1',
            color: '#422662'
          }}
          variant='body1'
        >
          {text}
        </Typography>
      </Box>
    )
  }


  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        mb: '60px',
      }}>
        <LegendDialog
          arrIntentions={vokaturiState}
          isOpen={showLegend}
          setIsOpen={setShowLegend}
        />
        <Box sx={{
          mr: '5px',
          mb: '10px',
          border: '1px solid #E2E2E2',
          borderRadius: '4px',
          backgroundColor: '#FFFFFF',
          padding: '20px 30px',
          paddingBottom: '20px',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '370px',
          width: '100%',
          boxSizing: 'border-box'
        }}>
          <Typography
            sx={{
              mb: '15px',
              fontSize: '13px',
              fontWeight: '400',
              textTransform: 'uppercase',
              letterSpacing: '.04em;',
              color: '#333333'
            }}
            variant='body1'
          >
            Top 5
          </Typography>
          <Box>
            <Tabs
              sx={{
                minHeight: '0',
                height: '33px',
                mb: '35px',
                borderRight: 1,
                borderColor: 'divider',
                '& .MuiTab-root': {
                  fontSize: '13px',
                }
              }}
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="tabs"
            >
              <Tab label="Vibes" {...a11yProps(0)} />
              <Tab label="Songs" {...a11yProps(1)} />
              <Tab label="Intentions" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            {Object.entries(tabsData[0]).map(([item, value]: any) => (
              <Box
                sx={{
                  mb: '15px',
                  display: 'flex'
                }}
                key={item}
              >
                <Typography
                  sx={{
                    minWidth: '40px',
                    mr: '25px',
                    fontWeight: '500',
                    fontSize: '21px',
                    lineHeight: '1',
                    color: '#422662'
                  }}
                  variant='body1'
                >
                  {value}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: '#333333'
                  }}
                  variant='body1'
                >
                  {item}
                </Typography>
              </Box>
            ))}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            {Object.entries(tabsData[1]).map(([item, value]: any) => (
              <Box
                sx={{
                  mb: '15px',
                  display: 'flex'
                }}
                key={item}
              >
                <Typography
                  sx={{
                    minWidth: '40px',
                    mr: '25px',
                    fontWeight: '500',
                    fontSize: '21px',
                    lineHeight: '1',
                    color: '#422662'
                  }}
                  variant='body1'
                >
                  {value}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: '#333333'
                  }}
                  variant='body1'
                >
                  {item}
                </Typography>
              </Box>
            ))}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            {Object.entries(tabsData[2]).map(([item, value]: any) => (
              <Box
                sx={{
                  mb: '15px',
                  display: 'flex'
                }}
                key={item}
              >
                <Typography
                  sx={{
                    minWidth: '40px',
                    mr: '25px',
                    fontWeight: '500',
                    fontSize: '21px',
                    lineHeight: '1',
                    color: '#422662'
                  }}
                  variant='body1'
                >
                  {value}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: '#333333'
                  }}
                  variant='body1'
                >
                  {item}
                </Typography>
              </Box>
            ))}
          </CustomTabPanel>
        </Box>
        <Box sx={{
          maxWidth: '460px',
          width: '100%',
          mr: '5px',
        }}>
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
          }}>
            <Box sx={{
              display: 'flex',
              width: '100%',
            }}>
              {renderStatusBlock('Avg light score', avgLightScore)}
              {renderVokaturiBlock()}
            </Box>
            <Box sx={{
              display: 'flex',
              width: '100%',
            }}>
              {renderStatusBlock('Avg resilience score', avgResilienceScore)}
              {renderStatusBlock('Avg HRV', avgHrv)}
            </Box>
            {renderStatusBlock('Avg coherence score', avgCoherenceScore, true)}
          </Box>
        </Box>
        <Box sx={{
          mr: '10px',
          mb: '10px',
          border: '1px solid #E2E2E2',
          borderRadius: '4px',
          backgroundColor: '#FFFFFF',
          padding: '20px 30px',
          paddingBottom: '20px',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '370px',
          width: '100%',
          boxSizing: 'border-box'
        }}>
          <Typography
            sx={{
              mb: '30px',
              fontSize: '13px',
              fontWeight: '400',
              textTransform: 'uppercase',
              letterSpacing: '.04em;',
              color: '#333333'
            }}
            variant='body1'
          >
            Meditation by location
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <PieChart
              sx={{
                '.MuiChartsTooltip-valueCell': {
                  display: 'none'
                }
              }}
              series={pieChartState}
              {...chartSizing}
            />
          </Box>
          <Box>
            {renderPieChartData()}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AnalyticsUsageReal;
