import { TAnalyticsDataFilters } from "../types/types";
import { handleApiError } from "./handleApiError";
import instance from './index';

export const getSessionAnalytics = async (
  filters: TAnalyticsDataFilters,
  sorting: { order: 'asc' | 'desc'; orderBy: 'startedAt' },
  page: number = 1
): Promise<any> => {
  try {
    let params = Object.entries(filters).map(([key, value]) => key + '=' + value.toISOString()).join('&');
    let sortingParams = `sorting[order]=${sorting.order}&sorting[orderBy]=${sorting.orderBy}`;
    const pageQuery = '?page=' + page;

    return await instance.get<any>(`admin/analytics/sessions${params !== '' ? pageQuery + '&' + params : pageQuery}&${sortingParams}`).then(res => res.data);
  } catch (error: any) {
    handleApiError(error);
    throw error;
  }
};

export const getSessionOverallAnalytics = async (filters: TAnalyticsDataFilters): Promise<any> => {
  try {
    let params = Object.entries(filters).map(([key, value]) => key + '=' + value.toISOString()).join('&');

    return await instance.get<any>(`admin/analytics/sessions/overall?${params}`).then(res => res.data);
  } catch (error: any) {
    handleApiError(error);
    throw error;
  }
};
