import { Box, Typography, Button, Pagination } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import SessionsTableReal from '../../blocks/sessionsTableReal';
import { Order, OrderBySessions } from '../../../types/types';
import { useResponseError } from '../../../hooks/useResponseError';
import { getComparator, searchSort, stableSort } from '../../../utils';
import { useAnalytics } from '../AnalyticsReal';
import { useDebounce } from '../../../hooks/useDebounce';
import { getSessionAnalytics, getSessionOverallAnalytics } from '../../../service/sessionAnalyticsService';
import { countryCodeNameDictionary } from '../../../data/data';

const AnalyticsSessionsReal = () => {
  const [sort, setSort] = useState('ALL');
  const [totalSesions, setTotalSessions] = useState('0');
  const [totalPages, setTotalPages] = useState(0);
  const [averageSessionLength, setAverageSessionLength] = useState('2 min 30 sec');
  const [cumulativeSessiontime, setCumulativeSessiontime] = useState('16h 30min');
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof OrderBySessions>('date');
  const [sessions, setSessions] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { responseError, setResponseError } = useResponseError(50000);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedDate } = useAnalytics();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 1000, '');

  useEffect(() => {
    setPageNumber(1);
  }, [selectedDate]);

  useEffect(() => {
    const buildSessionAnalytics = async () => {
      try {
        const from = selectedDate.startDay || new Date();
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        from.setMilliseconds(0);

        const to = selectedDate.endDay || new Date(from.getTime());
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        to.setMilliseconds(999);

        getSessionOverallAnalytics({from, to}).then((data) => {
          setTotalSessions(data.totalSessions);
          setAverageSessionLength(data.averageSessionLength);
          setCumulativeSessiontime(data.cumulativeSessionLength)
        })

        const {data, meta} = await getSessionAnalytics(
          {from, to},
          {order, orderBy: 'startedAt'},
          pageNumber
        );

        setTotalPages(Math.ceil(meta.total / meta.take));
        setSessions([
          ...(data as any[]).map((item: any, index: number) => {
            const topVibes = (item.vibeCheckInSessions || []).map((checkInSession: any) => checkInSession.topVibes).flat();
            const countryName = item.country ? countryCodeNameDictionary[item.country] : item.country;

            return {
              id: item.id,
              userId: item.userId,
              date: new Date(item.startedAt).toLocaleDateString(),
              time: new Date(item.startedAt).toLocaleTimeString(),
              length: item.sessionLength || '-',
              country: countryName || '-',
              vibes: topVibes.filter((topVibeItem: any) => topVibeItem.tunedVibeType === 'DETECTED').map((topVibeItem: any) => ({
                vibe: topVibeItem.vibe,
                color: topVibeItem.color,
              })),
              selected_intentions: topVibes.filter((topVibeItem: any) => topVibeItem.tunedVibeType === 'INTENTION').map((topVibeItem: any) => ({
                vibe: topVibeItem.vibe,
                color: topVibeItem.color,
              })),
              vokaturi: topVibes.filter((topVibeItem: any) => topVibeItem.tunedVibeType === 'DETECTED').map((topVibeItem: any) => ({
                vibe: topVibeItem.vibe,
                color: topVibeItem.color,
              })),
              stress: '-',
              light: '-',
              resilience: item.resilienceStatus || '-',
              hrv: item.avgHrv || '-',
            };
          }),
        ]);
      } catch(err) {
        console.log(err);
      }
    };

    buildSessionAnalytics();
  }, [pageNumber, selectedDate, order]);

  useEffect(() => {
    sortTable(debouncedSearchValue, sort, order, orderBy, 1);

    if (searchValue || debouncedSearchValue) {
      setHasMore(true);
    }
  }, [debouncedSearchValue]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const handleNextPage = (pgNumber: number) => {
    if (hasMore) {
      setPageNumber(pgNumber + 1)
    } else {
      setPageNumber(pgNumber);
    }
  }

  const handlePrevPage = (pgNumber: number) => {
    if (pgNumber > 0) {
      setPageNumber(pgNumber - 1)
    } else {
      setPageNumber(0);
    }
  }

  const handleResetSearch = () => {
    setPageNumber(1);
    sortTable(searchValue, sort, order, orderBy, 1);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof OrderBySessions,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const orderValue = isAsc ? 'desc' : 'asc';
    setOrder(orderValue);
    setOrderBy(property);
    setPageNumber(1);
    // sortTable(searchValue, sort, orderValue, property, 1);
  };

  const sortTable = useCallback(async (
    searchField: string,
    sortField: string,
    orderField: Order,
    orderByField: string,
    pgNumber: number
  ) => {
    if (!hasMore) return;

    setIsLoading(true);
    let data: {
      take: number,
      page?: number,
      search?: string,
      roles?: string,
      order: string,
      orderBy: string
    } = {
      page: pgNumber,
      take: 12,
      order: orderField,
      orderBy: orderByField
    }

    if (searchField !== '') {
      data.search = searchField;
    }

    try {
      const searchedSessions = data.search ? searchSort(sessions, data.search) : sessions;
      const formatData = stableSort(searchedSessions, getComparator(orderField, orderByField));
      setSessions(formatData);
      setHasMore(false);
    } catch (error: any) {
      setResponseError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [setResponseError]);

  const renderStatusBlock = (title: string, text: string) => {
    return (
      <Box sx={{
        mr: '10px',
        mb: '10px',
        border: '1px solid #E2E2E2',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        padding: '20px 30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'baseline',
      }}>
        <Typography
          sx={{
            mb: '15px',
            fontSize: '13px',
            fontWeight: '400',
            textTransform: 'uppercase',
            letterSpacing: '.04em;',
            color: '#333333'
          }}
          variant='body1'
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '34px',
            lineHeight: '1',
            color: '#422662'
          }}
          variant='body1'
        >
          {text}
        </Typography>
      </Box>
    )
  }

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        mb: '60px',
      }}>
        {renderStatusBlock('Total Sessions', totalSesions)}
        {renderStatusBlock('Average Session Length', averageSessionLength)}
        {renderStatusBlock('Cumulative session time', cumulativeSessiontime)}
      </Box>
      <Box sx={{
        position: 'relative',
        width: '100%',
        minHeight: '400px',
        mb: '60px',
      }}>
        <Box sx={[{
          maxWidth: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: '-2px',
        }]}>
          <Box sx={[{
            'display': 'flex',
            flexWrap: 'wrap',
            pb: '25px',
            maxWidth: '100%',
            width: '100%',
            overflow: 'auto',
          }]}>
            <SessionsTableReal
              order={order}
              orderBy={orderBy}
              handleRequestSort={handleRequestSort}
              data={sessions}
              page={pageNumber}
            />
          </Box>
          <Box sx={{
            mt: '50px',
            mb: '110px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            {pageNumber > 1 && <Button
              sx={{
                mr: '15px',
                color: '#000',
                borderColor: '#C9C9C9',
                '&:hover': {
                  borderColor: '#C9C9C9',
                  boxShadow: 'none',
                  opacity: '0.7'
                },
                '&:active': {
                  boxShadow: 'none',
                  borderColor: '#C9C9C9',
                },
              }}
              onClick={() => handlePrevPage(pageNumber)}
              variant="outlined"
              disabled={pageNumber === 1}
            >
              Previous Page
            </Button>}
            {totalPages > 1 && <Pagination
              sx={{
                mr: '15px',
              }}
              onChange={handleChangePage}
              page={pageNumber}
              hideNextButton={true}
              hidePrevButton={true}
              count={totalPages}
              variant="outlined"
              color="primary"
            />}
            <Button
              sx={{
                color: '#000',
                borderColor: '#C9C9C9',
                '&:hover': {
                  borderColor: '#C9C9C9',
                  boxShadow: 'none',
                  opacity: '0.7'
                },
                '&:active': {
                  boxShadow: 'none',
                  borderColor: '#C9C9C9',
                },
              }}
              onClick={() => handleNextPage(pageNumber)}
              variant="outlined"
              disabled={!hasMore}
            >
              Next Page
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AnalyticsSessionsReal;
