import { countryDictionary } from "../data/data";
import { TAnalyticsDataFilters } from "../types/types";
import { handleApiError } from "./handleApiError";
import instance from './index';

export const getSessionMapAnalytics = async (
  filters: TAnalyticsDataFilters,
  country?: string,
): Promise<any> => {
  try {
    let params = Object.entries(filters).map(([key, value]) => key + '=' + value.toISOString()).join('&');

    const countryCode = !country || country?.length === 2 ? country : countryDictionary[country];

    return await instance.get<any>(`admin/analytics/map?${params}${countryCode ? '&country='+countryCode : ''}`).then(res => res.data);
  } catch (error: any) {
    handleApiError(error);
    throw error;
  }
};
